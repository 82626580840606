import Button from "components/buttons/button"
import Seo from "components/seo"
import * as React from "react"
import "./new-game.scss"
import { WORD_GAMES_BASE_URL } from "../../constants"
import { fetchWrapper } from "../../helpers"

type Props = {}
type State = {
  isGameCreated: boolean
  gameUrl: string
  isError: boolean
  isLoading: boolean
}

export default class CreateGame extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isGameCreated: false,
      gameUrl: "",
      isError: false,
      isLoading: false,
    }
  }

  onClick() {
    return () => {
      const textarea: HTMLTextAreaElement = document.getElementById(
        "input-words"
      ) as HTMLTextAreaElement

      if (textarea && textarea.value) {
        this.setState({
          isLoading: true,
          isError: false,
        })

        fetchWrapper.post(`${WORD_GAMES_BASE_URL}create`, {
          words: textarea.value.split("\n"),
          gameType: "word-escape",
        })
          .then(data => {
            this.setState({
              isGameCreated: true,
              gameUrl: `${location.origin}/games/${data.gameType}?id=${data.id}`,
              isLoading: false,
            })
          })
          .catch(error => {
            console.error(error)
            this.setState({
              isError: true,
              isLoading: false,
            })
          })
      }
    }
  }

gameCreatedConfirmation() {
    return (
      <section id="confirmation" className="confirmation">
        <p>Your games was created. Go to <a href={this.state.gameUrl}>{this.state.gameUrl}</a></p>
      </section>
    )
  }

  render() {
    return (
      <main>
        <Seo title="Create Game" />
        <main id="new-game-form" className="new-game-form">
          <label htmlFor="input-words">
            Enter words or phrases separated by new line.
          </label>
          <textarea id="input-words" name="input-words"></textarea>
          <Button
            id="create-game"
            key="Create"
            disabled={this.state.isLoading}
            onClick={this.onClick()}
            className=""
          >
            Create
          </Button>
        </main>
        {this.state.isGameCreated ? this.gameCreatedConfirmation() : ""}
        {this.state.isError ? "Failed to create game." : ""}
      </main>
    )
  }
}
