import CreateGame from "../components/create-game/create-game"
import Layout from "components/layout/layout"
import * as React from "react"
import "styles/global.scss"

const IndexPage = () => (
  <Layout>
    <CreateGame />
  </Layout>
)

export default IndexPage
