import * as React from "react"
import { MouseEventHandler } from "react"
import "./button.scss"

type Props = {
  children: React.ReactNode
  onClick: MouseEventHandler<HTMLButtonElement>
  id: string
  disabled: boolean
  className?: string
}

export default class Button extends React.Component<Props> {

  render() {
    return (
      <button
        className={`button ${this.props.className ? this.props.className : ''} ${this.props.disabled ? "disabled" : ""
          }`}
        id={this.props.id.toLowerCase()}
        onClick={this.props.onClick}
        type="button"
        tabIndex={this.props.disabled ? -1 : 0}
      >
        {this.props.children}
      </button>
    )
  }
}
